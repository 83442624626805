import axios from 'src/app-axios';
import Constants from 'src/constants';
import moment from 'moment';

export const digiboards = {
    namespaced: true,
    state: {
        digiBoards: [],
        boardActivity: {},
        boardActivityUpdatedAt: '',
        deviceRegistrationSuccessData: [],
        boardDetails: {},
        latestThreeBoardActivities: [],
        digiBoardLicenceCounts: {},
        digiBoardTimeSlots: [],
        digiBoardTimeSlotPrices: {},
        organizationDigiBoardTimeSlots: [],
        selectedOrgBoardsForContentLinking: [],
        boardImages: [],

    },
    getters: {
        getDigiBoards (state) {
            return state.digiBoards;
        },
        getBoardActivity (state) {
            return state.boardActivity;
        },
        getActivityLastUpdatedAt (state) {
            return state.boardActivityUpdatedAt;
        },
        getDeviceRegistrationSuccessData (state) {
            return state.deviceRegistrationSuccessData;
        },
        getBoardDetails (state) {
            return state.boardDetails;
        },
        getLatestThreeBoardActvities (state) {
            return state.latestThreeBoardActivities;
        },
        getDigiBoardLicenceCounts (state) {
            return state.digiBoardLicenceCounts;
        },
        getDigiBoardTimeSlots (state) {
            return state.digiBoardTimeSlots;
        },
        getDigiBoardTimeSlotPrices (state) {
            return state.digiBoardTimeSlotPrices;
        },
        getOrganizationDigiBoardTimeSlots (state) {
            return state.organizationDigiBoardTimeSlots;
        },
        getSelectedOrgBoardsForContentLinking (state) {
            return state.selectedOrgBoardsForContentLinking;
        },
        getBoardImages (state) {
            return state.boardImages;
        },
    },
    actions: {
        async fetchDigiBoards ({ dispatch, commit }) {
            const url = 'api/digi-boards';
            try {
                const details = await axios.get(url);
                commit('setDigiBoards', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your device list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        addBoardActivity (context, event) {
            context.commit('setBoardActivity', {
                digiBoardId: event.digi_board_id,
                event: event.event_record,
            });
            context.commit('setBoardUpdatedAt', moment().toISOString());
        },
        async fetchLatestBoardActivity ({ dispatch, commit, state }, { params }) {
            const url = 'api/digi-boards/update-records';
            try {
                const response = await axios.get(url, { params: params });
                for (const result of response.data) {
                    commit('setBoardActivities', {
                        digiBoardId: result.digi_board_id,
                        eventRecords: response.data,
                    });
                }
                commit('setBoardUpdatedAt', moment().toISOString());
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your latest device activities.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async activateDevice ({ dispatch }, payload) {
            const url = `api/digi-boards/${payload.digiBoardId}/register`;
            try {
              await axios.post(url, {
                registration_key: payload.licenceKey,
              });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in activating your device.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        addDeviceRegistrationSuccessData (context, event) {
            context.commit('setDeviceRegistrationSuccessData', event.digi_board_id);
        },
        clearDeviceRegistrationSuccessData ({ commit }) {
            commit('clearOffDeviceRegistrationSuccessData');
        },
        async fetchBoardDetails ({ dispatch, commit }, payload) {
            const url = `api/digi-boards/${payload.digiBoardId}`;
            try {
                const details = await axios.get(url);
                if (details.data.length === 0) {
                    return;
                }
                commit('setBoardDetails', details.data[0]);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your device details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchLatestThreeBoardActvities ({ dispatch, commit }, { params }) {
            const url = 'api/digi-boards/update-records';
            try {
                const response = await axios.get(url, { params: params });
                commit('setLatestThreeBoardActivities', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your latest device activities.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchDigiBoardLicenceCounts ({ dispatch, commit }) {
            const url = 'api/digi-boards/licences/status-count';
            try {
                const count = await axios.get(url);
                commit('setDigiBoardLicenceCounts', count.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting device licence count data.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateDigiBoardDetails ({ dispatch }, payload) {
            const url = `api/digi-boards/${payload.id}`;
            try {
                await axios.put(url, payload.editedBoardDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your Device details',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchDigiBoardsUnderOrganization ({ dispatch, commit }, payload) {
            const url = `api/digi-boards/organizations/${payload.organizationId}`;
            try {
                const details = await axios.get(url);
                commit('setDigiBoards', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your selected locations device list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchDigiBoardTimeSlots ({ dispatch, commit }, payload) {
            const url = `api/digi-boards/${payload.digiBoardExternalId}/time-slots`;
            try {
                const details = await axios.get(url);
                commit('setDigiBoardTimeSlots', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the selected device time slots.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchDigiBoardTimeSlotPrices ({ dispatch, commit }, payload) {
            const url = `api/digi-boards/time-slots/${payload.digiBoardTimeSlotId}/products`;
            try {
                const product = await axios.get(url);
                commit('setDigiBoardTimeSlotPrices', product.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the selected time slots plan details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchOrganizationDigiBoardTimeSlots ({ dispatch, commit }) {
            const url = 'api/organizations/time-slots';
            try {
                const details = await axios.get(url);
                commit('setOrganizationDigiBoardTimeSlots', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your device list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        addSelectedOrgBoardsForContentLinking (context, boards) {
            context.commit('setSelectedOrgBoardsForContentLinking', boards);
        },
        clearOffSelectedOrgBoardsForContentLinking ({ commit }) {
            commit('clearSelectedOrgBoardsForContentLinking');
        },
        async fetchBoardImages ({ dispatch, commit }, { params }) {
            const url = 'api/company-files';
            try {
                const images = await axios.get(url, { params: params });
                commit('setBoardImages', images.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting this board images.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
    mutations: {
        setDigiBoards (state, digiBoards) {
            state.digiBoards = digiBoards;
        },
        setBoardActivity (state, { digiBoardId, event }) {
            if (digiBoardId in state.boardActivity) {
                state.boardActivity[digiBoardId].unshift(event);
                // we are storing only last 3 events.
                state.boardActivity[digiBoardId].length = 3;
                return;
            }

            state.boardActivity[digiBoardId] = [event];
        },
        setBoardActivities (state, { digiBoardId, eventRecords }) {
            if (digiBoardId in state.boardActivity) {
                // we are storing only last 3 events.
                state.boardActivity[digiBoardId] = [];
            }
            state.boardActivity[digiBoardId] = eventRecords;
        },
        setBoardUpdatedAt (state, dateTime) {
            state.boardActivityUpdatedAt = dateTime;
        },
        setDeviceRegistrationSuccessData (state, data) {
            state.deviceRegistrationSuccessData.push(data);
        },
        clearOffDeviceRegistrationSuccessData (state) {
            state.deviceRegistrationSuccessData = [];
        },
        setBoardDetails (state, data) {
            state.boardDetails = data;
        },
        setLatestThreeBoardActivities (state, data) {
            state.latestThreeBoardActivities = data;
        },
        setDigiBoardLicenceCounts (state, count) {
            state.digiBoardLicenceCounts = count;
        },
        setDigiBoardTimeSlots (state, data) {
            state.digiBoardTimeSlots = data;
        },
        setDigiBoardTimeSlotPrices (state, data) {
            state.digiBoardTimeSlotPrices = data;
        },
        setOrganizationDigiBoardTimeSlots (state, data) {
            state.organizationDigiBoardTimeSlots = data;
        },
        setSelectedOrgBoardsForContentLinking (state, boards) {
            state.selectedOrgBoardsForContentLinking = boards;
        },
        clearSelectedOrgBoardsForContentLinking (state) {
            state.selectedOrgBoardsForContentLinking = [];
        },
        setBoardImages (state, data) {
            state.boardImages = data;
        },
    },
};
