import axios from 'src/app-axios';
import Constants from 'src/constants';

export const payments = {
    namespaced: true,
    state: {
        checkoutSession: {},
        subscriptionsHistory: [],
        invoiceUrls: {},
        purchaseDetails: {},
        oneTimePaymentHistory: [],
        trialEndsAtNotification: {},
        trialExpiredNotification: {},
        productsCart: [],
    },
    getters: {
        getCheckoutSession (state) {
            return state.checkoutSession;
        },
        getSubscriptionsHistory (state) {
            return state.subscriptionsHistory;
        },
        getInvoiceDownloadUrl (state) {
            return state.invoiceUrls;
        },
        getPurchaseDetails (state) {
            return state.purchaseDetails;
        },
        getOneTimePaymentHistory (state) {
            return state.oneTimePaymentHistory;
        },
        getTrialEndsAtNotification (state) {
            return state.trialEndsAtNotification;
        },
        getTrialExpiredNotification (state) {
            return state.trialExpiredNotification;
        },
        getProductsCart (state) {
            return state.productsCart;
        },
    },
    mutations: {
        setCheckoutSession (state, session) {
            state.checkoutSession = session;
        },
        setSubscriptionsHistory (state, history) {
            state.subscriptionsHistory = history;
        },
        setInvoiceDownloadUrl (state, data) {
            state.invoiceUrls = data;
        },
        setPurchaseDetails (state, details) {
            state.purchaseDetails = details;
        },
        setOneTimePaymentHistory (state, history) {
            state.oneTimePaymentHistory = history;
        },
        setTrialEndsAtNotification (state, message) {
            state.trialEndsAtNotification = message;
        },
        setTrialExpiredNotification (state, message) {
            state.trialExpiredNotification = message;
        },
        setProductsCart (state, data) {
            state.productsCart.push(data);
        },
        removeSavedProduct (state, index) {
            state.productsCart.splice(index, 1);
        },
        clearOffProductsCart (state) {
            state.productsCart = [];
        },
    },
    actions: {
        async createCheckoutSession ({ dispatch, commit }, payload) {
            const url = 'api/payments/session-create';
            try {
                const response = await axios.post(url, payload);
                commit('setCheckoutSession', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating your checkout session.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createCheckoutCustomer ({ dispatch }, payload) {
            const url = 'api/payments/users';
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating customer for checkout.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async checkoutSessionCompleted  ({ dispatch, commit }, payload) {
            const url = 'api/payments/session-completed';
            try {
                const details = await axios.post(url, payload);
                commit('setPurchaseDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in completing your checkout session.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchSubscriptionsHistory ({ dispatch, commit }) {
            const url = 'api/payments/subscriptions';
            try {
                const details = await axios.get(url);
                commit('setSubscriptionsHistory', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your payments history.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchInvoiceDownloadUrl ({ dispatch, commit }, payload) {
            const url = `api/payments/${payload.purchaseMode}/${payload.subscriptionId}/invoices`;
            try {
                const details = await axios.get(url);
                commit('setInvoiceDownloadUrl', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in downloading your purchase invoice.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchOneTimePaymentHistory ({ dispatch, commit }) {
            const url = 'api/payments/payment-intents';
            try {
                const details = await axios.get(url);
                commit('setOneTimePaymentHistory', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your payments history.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        recordTrialEndsAtNotification (context, message) {
            context.commit('setTrialEndsAtNotification', message);
        },
        recordTrialExpiredNotification (context, message) {
            context.commit('setTrialExpiredNotification', message);
        },
        addProductsToCart (context, payload) {
            context.commit('setProductsCart', {
                digiBoardDetails: payload.digiBoardDetails,
                productPrice: payload.productPrice,
            });
        },
        removeCartedProducts (context, index) {
            context.commit('removeSavedProduct', index);
        },
        async startFreeTrial ({ dispatch }, payload) {
            const url = `api/digi-boards/time-slots/${payload.digiBoardTimeSlotId}/trial`;
            try {
                await axios.post(url, payload.priceId);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating a free trial subscription',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearOffProductsCart ({ commit }) {
            commit('clearOffProductsCart');
        },
    },
};
