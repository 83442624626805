<template>
  <div>
    <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      transition="scale-transition"
      multi-line
      bottom
      left
      :timeout="timeOut"
    >
      {{ alertMessage }}
      <v-icon
        dark
        large
        left
      >
        {{ alertIcon }}
      </v-icon>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showAlert = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Constants from 'src/constants';

  export default {
    data: () => ({
      showAlert: false,
      alertMessage: '',
      alertColor: '',
      alertIcon: 'error',
      alertTitle: 'Error',
      timeOut: 5000,
    }),
    created () {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'alert/addAlert') {
          if (state.alert.type === Constants.ALERT_TYPE_ERROR) {
            this.createErrorAlert(state.alert.message, state.alert.title);
          }
          if (state.alert.type === Constants.ALERT_TYPE_SUCCESS) {
            this.createSuccessAlert(state.alert.message, state.alert.title);
          }
          if (state.alert.type === Constants.ALERT_TYPE_INFO) {
            this.createInfoAlert(state.alert.message, state.alert.title);
          }
        }
      });
    },
    methods: {
      createErrorAlert (message, title) {
        this.alertMessage = message;
        this.alertColor = '#E53935';
        this.alertIcon = 'mdi-alert-circle';
        this.alertTitle = title;
        this.showAlert = true;
      },
      createSuccessAlert (message, title) {
        this.alertMessage = message;
        this.alertColor = '#43A047';
        this.alertIcon = 'mdi-checkbox-marked-circle-outline';
        this.alertTitle = title;
        this.showAlert = true;
      },
      createInfoAlert (message, title) {
        this.alertMessage = message;
        this.alertColor = '#FF3700';
        this.alertIcon = 'mdi-information-outline';
        this.alertTitle = title;
        this.showAlert = true;
      },
    },
  };
</script>
