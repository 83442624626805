import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Login',
      component: () => import('@/views/auth/Login'),
    },
    // new user registration
    {
      name: 'User Registration Form',
      path: '/register',
      component: () => import('@/views/UserRegistration/UserRegistrationForm'),
    },
    {
      name: 'User Registering Process',
      path: '/register/:userId/:linkId',
      component: () => import('@/views/UserRegistration/UserRegisteringProcess'),
    },
    // reset Password
    {
      name: 'Email Confirmation',
      path: '/password/reset',
      component: () => import('@/views/PasswordReset/PasswordResetInitialPage'),
    },
    {
      name: 'Password Resetting Process',
      path: '/password/:userId/:linkId',
      component: () => import('@/views/PasswordReset/PasswordResettingProcess'),
    },
    {
      name: 'Create New Password',
      path: '/password/update',
      component: () => import('@/views/PasswordReset/CreateNewPassword'),
    },
    // Auth
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'pages/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: '/user',
          component: () => import('@/views/dashboard/ProfileSettings/ProfileSettings'),
        },
        // Banners
        {
          name: 'Banners List',
          path: '/banners',
          component: () => import('@/views/dashboard/banners/Banners'),
        },
        {
          name: 'Create Banner',
          path: '/banner/create',
          component: () => import('@/views/dashboard/banners/CreateNewBanner'),
        },
        {
          name: 'Media Library',
          path: '/banner/medialibrary',
          component: () => import('@/views/dashboard/banners/BannerGallery'),
        },
        // campaigns
        {
          name: 'Campaign List',
          path: '/campaigns',
          component: () => import('@/views/dashboard/campaigns/CampaignList'),
        },
        {
          name: 'Create Campaign',
          path: '/campaign/create',
          component: () => import('@/views/dashboard/campaigns/CreateCampaigns'),
        },
        {
          name: 'Edit Campaign',
          path: '/campaign/:id',
          component: () => import('@/views/dashboard/campaigns/EditCampaign'),
        },
        // Swift Checkin
        {
          name: 'Checkin Records',
          path: '/swift-checkin/records',
          component: () => import('@/views/dashboard/swiftcheckin/RecordList'),
        },
        {
          name: 'Checkin Projects',
          path: '/swift-checkin/projects',
          component: () => import('@/views/dashboard/swiftcheckin/projects/ProjectList'),
        },
        {
          name: 'Checkin Settings',
          path: '/swift-checkin/settings',
          component: () => import('@/views/dashboard/swiftcheckin/settings/SettingsHome'),
        },
        // Maps
        {
          name: 'Reports',
          path: '/reports',
          component: () => import('@/views/dashboard/reports/Reports'),
        },
        // support
        {
          name: 'Support',
          path: '/support',
          component: () => import('@/views/dashboard/support/Support'),
        },
        // digiBoard
        {
          name: 'Digi-Boards',
          path: '/digi-board',
          component: () => import('@/views/dashboard/digiboard/DigiBoardsLandingPage'),
        },
        {
          name: 'Digiboard Details',
          path: '/digi-board/:external_id',
          component: () => import('@/views/dashboard/digiboard/DigiBoardDetailsLandingPage'),
        },
        // digiBoard Grouping
        {
          name: 'Device Structure',
          path: '/digi-board/group',
          component: () => import('@/views/dashboard/DigiBoardGrouping/CreateDigiBoardStructure'),
        },
        {
          name: 'Digiboard Time Slots',
          path: '/digi-board/:external_id/time-slots',
          component: () => import('@/views/dashboard/digiboard/DbsDigiBoardDetailsLanding'),
        },
        {
          name: 'Digiboard Time Slot Prices',
          path: '/digi-board/:external_id/time-slots/:digi_board_time_slot_id/products',
          component: () => import('@/views/dashboard/digiboard/DigiBoardTimeSlotPrices'),
        },
        // Account Settings
        {
          name: 'Settings',
          path: '/settings',
          component: () => import('@/views/dashboard/settings/Settings'),
        },
        // PosterMyWall Editor
        {
          name: 'PosterMyWall Redirect Uri',
          path: '/content-editor/auth-redirect',
          component: () => import('@/views/dashboard/PosterMyWall/PosterMyWallOauthStepTwo'),
        },
        {
          name: 'PosterMyWall Editor Landing Page',
          path: '/content-editor/postermywall',
          component: () => import('@/views/dashboard/PosterMyWall/PosterMyWallEditorLandingPage'),
        },
        // Payments
        {
          name: 'Products Cart',
          path: '/payments/products-cart',
          component: () => import('@/views/dashboard/ProfileSettings/payments/ProductPurchaseCart'),
        },
        {
          name: 'Checkout Success',
          path: '/success/:id',
          component: () => import('@/views/dashboard/ProfileSettings/payments/CheckoutSuccess'),
        },
        {
          name: 'Checkout Cancel',
          path: '/cancel/:id',
          component: () => import('@/views/dashboard/ProfileSettings/payments/CheckoutCancel'),
        },
        // Widgets
        {
          name: 'Widgets',
          path: '/widgets',
          component: () => import('@/views/dashboard/widgets/Widgets'),
        },
        {
          name: 'Create Ticker',
          path: 'ticker/create',
          component: () => import('@/views/dashboard/widgets/tickers/CreateTicker'),
        },
        // Logout
        {
          path: '/logout',
          name: 'Logout',
          component: () => import('@/views/auth/Logout'),
        },
        // unknown routes
        {
          name: 'unknown',
          path: '*',
          component: () => import('@/views/dashboard/Unknown'),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/');
    return;
  }

  next();
});

export default router;
