<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
    :to="pageRoute(item.to)"
  >
    <v-list-item-icon
      v-if="text"
      class="v-list-item__icon--text"
      v-text="computedText"
    />

    <v-list-item-icon v-else-if="item.icon">
      <v-icon
        v-text="item.icon"
      />
    </v-list-item-icon>

    <v-list-item-content
      v-if="item.title || item.subtitle"
      class="nav-titles"
    >
      <v-list-item-title
        class="title-text"
        v-text="item.title"
      />
      <div>
        <span
          v-if="isPlanExpired && item.title !== 'Devices'"
          class="subscribe-text"
        >Subscribe <span class="right-arrow-style">>></span></span>
        <span
          v-else
          class="title-text-on-hover"
        >
          {{ item.title }}
        </span>
      </div>
      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable';

  export default {
    name: 'Item',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      computedText () {
        if (!this.item || !this.item.title) return '';

        let text = '';

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1);
        });

        return text;
      },
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined);
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    methods: {
      pageRoute (routePath) {
        if (this.isPlanExpired === true && this.item.title !== 'Devices') {
          return '';
        } else {
          return routePath;
        }
      },
    },
  };
</script>
<style scoped>
.nav-titles .subscribe-text {
  display: none;
}

.nav-titles:hover .title-text {
  display: none;
}

.nav-titles:hover .subscribe-text {
  display: inline;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  font-weight: 600;
  color: #CFD8DC;
}
.nav-titles .title-text-on-hover {
  display: none;
}
.nav-titles:hover .title-text-on-hover {
  display: inline;
}
.right-arrow-style {
  font-size: 20px;
  font-weight: bold;
  color: #FF3700;
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
